import "./AboutUsStyles.css"

function AboutUs() {
    return (
        <div className="about-container">
            <h1>Aarakshaa</h1>
            <p>
                Aarakshaa means “PROTECTION”in Sinhalese [or in Sri Lankan]. The idea is to protect everything what you think is important for you and your family.

                Most of us have three main dreams in everyone’s life, Firstly, to give a good education to kids and make them good citizens of New Zealand, Secondly, to buy a decent house to live with your loved ones and Finally, to retire with a solid bank balance.

                We at AARAKSHAA committed to help you achieve all those dreams with realistic plans.
            </p>

            <p>
                Firstly, you need to realize that you need to be around your family to guide them so having a great personal insurance consist of Life, Trauma, Income and Medical covers are very important. We have over 8 years of experience sorting out over 1500 clients in order to offer the best suitable plans for their individual needs in Aoteroa. So why not you!!! Call us today and sort yours too.
            </p>

            <p>
                Secondly, our experienced team will help you to sort out your first home or your investment property from the Bank that suits you well. When we say a team it is not just one person but got four qualified Mortgage Brokers to discuss and decide the best package which suits your family well. We understand that you are busy too and you don’t have time to go to each and every bank to find out which offers you the best deal, instead, we will do the hard work for you and get the most suitable and relevant advice within a very short period of time. Why wait, give our friendly team a call now…
            </p>

            <p>Finally, when we grow old we naturally think about a peaceful retirement. It can only be done if you have a good bank balance at the time you retire. We all know that KIWI-SAVER is our main source of retirement fund and unfortunately, the majority have no idea what needs to be done in order increase the retirement fund when you need it most. We work with a few KiwiSaver funds so we can make sure that you money will be in the right fund to give you the best return depending on your age, income and the time left for retirement. Remember it is also equally important to have your money invested in the ethical and even environmentally friendly funds, don’t worry we will educate you so that you not only grow your money but socially responsible too.</p>
        </div>
    );
}

export default AboutUs;