import "./DisclosureStyles.css"
import React from 'react';
import complaintsdoc from '../assets/NDA/Complaints Handling Process.pdf';
import disclosuredoc from '../assets/NDA/Disclosure Statement - AARAKSHAA I & M - NEW (1).pdf';

const Disclosure= () =>{
    const handleComplaints = () => {
        const downloadUrl = complaintsdoc;
        const file_name = "Complaints Handling Document"
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const handledisclosure = () => {
        const downloadUrl = disclosuredoc;
        const file_name = "Disclosure Document"
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', file_name);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
   
      return (
        <div className="disclosure-container">
        <h1>Public Disclosure and Complaints handling Documentation</h1>
        <p>
           Please download our full public disclosure summary for your reference.
        </p>
        <button className="download-button" onClick={handledisclosure}>Download Disclosure</button>
        <p>
           Please download our complaints handling process documentation
        </p>
        <button className="download-button" onClick={handleComplaints}>Download Complaints Documentation</button>
    </div>
      )
    
}

export default Disclosure;